<template>
  <div class="mx-6 flex items-center py-1 px-3 bg-gray-100 rounded font-bold">
    <div class="inline-block">
      <svg
        v-if="!folderStack.length"
        @click="$router.go(-1)"
        xmlns="http://www.w3.org/2000/svg"
        width="30"
        height="30"
        viewBox="0 0 24 24"
        fill="none"
        class="mr-2 hover:bg-gray-100 rounded-full px-1 cursor-pointer"
        stroke="#000000"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      >
        <path d="M15 18l-6-6 6-6" />
      </svg>
      <svg
        v-else
        @click="
          folderStack.pop(),
            getManualFolders({
              brand: this.selectedBrand._id,
              folder: folderStack[folderStack.length - 1]
                ? folderStack[folderStack.length - 1]._id
                : null,
            })
        "
        xmlns="http://www.w3.org/2000/svg"
        width="30"
        height="30"
        viewBox="0 0 24 24"
        fill="none"
        class="mr-2 hover:bg-gray-100 rounded-full px-1 cursor-pointer"
        stroke="#000000"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      >
        <path d="M15 18l-6-6 6-6" />
      </svg>
    </div>
    {{ selectedBrand ? selectedBrand.name : "" }}
    <span v-for="folder in folderStack" :key="folder._id"
      >&rarr; {{ folder.name }}</span
    >
  </div>
  <div class="grid grid-cols-8 p-5 gap-5">
    <button
      @click="
        folderStack.push(folder),
          getManualFolders({
            brand: this.selectedBrand._id,
            folder: folderStack[folderStack.length - 1]._id,
          })
      "
      v-for="folder in manualFolders"
      :key="folder._id"
      class="
        py-6
        cursor-pointer
        z-10
        relative
        bg-gray-200
        text-center
        rounded-2xl
        transform
        hover:shadow-xl hover:-translate-y-1 hover:scale-108
        duration-300
        ease-in-out
      "
    >
      <p>
        <svg
          class="mx-auto mb-4"
          xmlns="http://www.w3.org/2000/svg"
          width="40"
          height="40"
          viewBox="0 0 24 24"
          fill="none"
          stroke="#000000"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path
            d="M11 21H4a2 2 0 0 1-2-2V5c0-1.1.9-2 2-2h5l2 3h9a2 2 0 0 1 2 2v2M19 15v6M16 18h6"
          />
        </svg>
      </p>
      <h1 class="uppercase text-black font-bold text-xs py-1">
        {{ folder.name }}
      </h1>
    </button>
    <a
      v-for="manual in partManuals"
      :key="manual._id"
      :href="`${api_host}/uploads/${manual.file}`"
      target="_blank"
      class="
        py-6
        cursor-pointer
        z-10
        relative
        bg-gray-200
        text-center
        rounded-2xl
        transform
        hover:shadow-xl hover:-translate-y-1 hover:scale-108
        duration-300
        ease-in-out
      "
    >
      <p>
        <svg
          class="mx-auto mb-4"
          xmlns="http://www.w3.org/2000/svg"
          width="40"
          height="40"
          viewBox="0 0 24 24"
          fill="none"
          stroke="#000000"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path
            d="M14 2H6a2 2 0 0 0-2 2v16c0 1.1.9 2 2 2h12a2 2 0 0 0 2-2V8l-6-6z"
          />
          <path d="M14 3v5h5M16 13H8M16 17H8M10 9H8" />
        </svg>
      </p>
      <h1 class="uppercase text-black font-bold text-xs py-1">
        {{ manual.name }}
      </h1>
    </a>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "PartManuals",
  data() {
    return {
      folderStack: [],
      api_host: process.env.VUE_APP_API_HOST,
    };
  },
  methods: {
    ...mapActions(["getManualFolders", "getBrands"]),
  },
  computed: {
    ...mapGetters(["partManuals", "brands", "manualFolders"]),

    selectedBrand() {
      if (!this.brands || !this.brands.length) return null;
      const selectedIndex = this.brands.findIndex(
        (brand) => brand._id === this.$route.params.brandid
      );
      if (selectedIndex === -1) return null;
      return this.brands[selectedIndex];
    },
  },
  mounted() {
    this.getManualFolders({
      brand: this.$route.params.brandid,
    });
    this.getBrands();
  },
  components: {},
};
</script>


<style scoped>
</style>


